export const educationData = [
    {
        id: 1,
        institution: 'National Institute of Technology, Patna',
        course: 'Bachelor of Technology',
        startYear: '2014',
        endYear: '2018'
    },
    {
        id: 2,
        institution: 'Naraya Junior College',
        course: 'Higher Secondary Education',
        startYear: '2012',
        endYear: '2014'
    }
]