import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'
import thirteen from '../assets/svg/projects/thirteen.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'TMDB Box-Office Prediction',
        projectDesc: 'This project aims to build a system that predicts Box-Office revenue of a movie.',
        tags: ['Python', 'ML'],
        code: 'https://github.com/VinodKumar9576/ML-Problems/tree/master/TMDB%20Prediction',
        demo: 'https://github.com/VinodKumar9576/ML-Problems/tree/master/TMDB%20Prediction',
        image: thirteen
    },
    /*{
        id: 2,
        projectName: 'Railway Tracking',
        projectDesc: 'This project is a railway tracking system that allows users to obtain relevant information about train timing.',
        tags: ['Django', 'Tailwind CSS'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: two
    },
    {
        id: 3,
        projectName: 'Weather App',
        projectDesc: 'Weather forecast systems and applications predict weather conditions based on multiple parameters.',
        tags: ['Django', 'CSS', 'Material Ui'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: three
    },
    {
        id: 4,
        projectName: 'Android Patient Tracker',
        projectDesc: 'This project involves the development of an Android application for viewing and managing patient data.',
        tags: ['Flutter', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: four
    },
    {
        id: 5,
        projectName: 'E-Commerce App',
        projectDesc: 'A Simple E-commerce application',
        tags: ['React Native', 'Firebase'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: five
    },
    {
        id: 6,
        projectName: 'Uber Lite',
        projectDesc: 'Uber clone',
        tags: ['Flutter'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: six
    },*/
    {
        id: 7,
        projectName: 'Stocks Alert Screener',
        projectDesc: 'A simple stock market alert API',
        tags: ['Python', 'Telegram', 'Chartink', 'BeautifulSoup'],
        code: 'https://github.com/VinodKumar9576/ML-Problems/tree/master/Stock%20Screener',
        demo: 'https://github.com/VinodKumar9576/ML-Problems/tree/master/Stock%20Screener',
        image: seven
    }
    
    /*{
        id: 8,
        projectName: 'Car Pooling System',
        projectDesc: 'The carpooling system merges multiple people in a car which leads to meet new people, reduces pollution',
        tags: ['Flutter', 'React'],
        code: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        demo: 'https://github.com/hhhrrrttt222111/developer-portfolio',
        image: eight
    },*/
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/