export const achievementData = {
    bio : "Data analyst with 5 years' experience in Python, SQL, and Excel, unraveling complex datasets. Collaborated on diverse projects—lifetime value calculations, student success models—across teams. Proficient in Looker, PowerBI, and versed in machine learning concepts. Focused on translating data insights into impactful strategies.",
    achievements : [
        {
            id : 1,
            title : 'Advanced Certification in Data Science',
            details : 'Validates proficiency in advanced statistical analysis, machine learning algorithms, and data manipulation for solving real-world business challenges.',
            date : 'Mar 21, 2023',
            field : 'Automation',
            image : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS4RtGzBqVn9mzH1A7xCElSkgtkyFCD2Adkgw&usqp=CAU'  
        },
        {
            id : 2,
            title : 'AWS Certification',
            details : 'Validate technical skills and cloud expertise to grow your career and business.',
            date : 'Jul 20, 2021',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1523474253046-8cd2748b5fd2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        },
        {
            id : 3,
            title : 'Professional Data Engineer Certification',
            details : 'Professional Data Engineers enable data-driven decision making by collecting, transforming, and publishing data.',
            date : 'Aug 20, 2019',
            field : 'Automation',
            image : 'https://images.unsplash.com/photo-1489389944381-3471b5b30f04?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80'
        }

    ]
}


// Do not remove any fields.
// Leave it blank instead as shown below.

/*

export const achievementData = {
    bio : "",
    achievements : []
}

*/