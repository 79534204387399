import img1 from '../assets/png/publications/paper2img.png'

export const blogData = [
    {
        id: 1,
        title: 'Spatial Context Energy Curve-Based Multilevel 3-D Otsu Algorithm for Image Segmentation',
        description: 'Here we will use Selenium to navigate between web pages, and try to scrape data from them.',
        date: 'May 5, 2021',
        image: 'https://www.frontiersin.org/files/Articles/1041799/fninf-16-1041799-HTML/image_m/fninf-16-1041799-g001.jpg',
        url: 'https://ieeexplore.ieee.org/document/8730517'
    },
    {
        id: 2,
        title: 'Lightning search algorithm-based contextually fused multilevel image segmentation',
        description: 'In this paper, a fusion-energy based image segmentation is proposed. Lightning Search algorithm (LSA) is used for optimal multilevel thresholding.',
        date: 'Mar 19, 2020',
        image: img1,
        url: 'https://www.sciencedirect.com/science/article/abs/pii/S1568494620301836?CMX_ID=&SIS_ID=-1&dgcid=STMJ_75273_AUTH_SERV_PPUB&utm_acid=1166577354&utm_campaign=STMJ_75273_AUTH_SERV_PPUB&utm_dgroup=Email1Publishing&utm_in=DM663945&utm_medium=email&utm_source=AC'
    },
    {
        id: 3,
        title: 'A local contrast fusion based 3D Otsu algorithm for multilevel image segmentation',
        description: 'To overcome the shortcomings of 1D and 2D Otsu\'s thresholding techniques, the 3D Otsu method has been developed.',
        date: 'Aug 18, 2019',
        image: 'https://d3i71xaburhd42.cloudfront.net/bb425e0359b680c57e83249f8ba084f647ab68d7/13-Figure11-1.png',
        url: 'https://ieeexplore.ieee.org/document/8945492'
    },
    {
        id: 4,
        title: 'Cuttlefish Algorithm Based Multilevel 3D Otsu Function for Color Image Segmentation',
        description: 'In this paper, the cuttlefish algorithm (CFA) based 3D Otsu thresholding method is proposed to pace up the conventional 3D Otsu thresholding for color image segmentation.',
        date: 'Jun 12, 2019',
        image: 'https://static.hindawi.com/articles/complexity/volume-2021/5564690/figures/5564690.fig.001.jpg',
        url: 'https://ieeexplore.ieee.org/document/8735812'
    },
    {
        id: 5,
        title: 'A context sensitive energy thresholding based 3D Otsu function for image segmentation using human learning optimization',
        description: 'A novel context-based 3D Otsu algorithm using human learning optimization (HLO) is proposed for multilevel color image segmentation.',
        date: 'Jun 9, 2019',
        image: 'https://www.bogotobogo.com/python/OpenCV_Python/images/thresholding/Otsu_Thresholding_EinStein.png',
        url: 'https://www.sciencedirect.com/science/article/abs/pii/S1568494619303503'
    },
]




// Do not remove any fields.
// Leave it blank instead as shown below.


/* 
{
    id: 1,
    title: 'Car Pooling System',
    description: '',
    date: 'Oct 1, 2020',
    image: '',
    url: 'https://preview.colorlib.com/theme/rezume/'
}, 
*/