export const experienceData = [
    {
        id: 1,
        company: 'Kaplan India',
        jobtitle: 'Data Analyst',
        startYear: '2021',
        endYear: '2022'
    },
    {
        id: 2,
        company: 'MH Alshaya',
        jobtitle: 'Data Analyst',
        startYear: '2018',
        endYear: '2021'
    },
]