export const socialsData = {
    github: 'https://github.com/VinodKumar9576',
    //facebook: 'https://www.facebook.com/',
    linkedIn: 'https://www.linkedin.com/in/vinod-kumar-immadisetty/',
    //instagram: 'https://www.instagram.com/',
    //codepen: 'https://codepen.io/',
    twitter: 'https://twitter.com/vinu_9576',
    //reddit: 'https://www.reddit.com/user/',
    //blogger: 'https://www.blogger.com/',
    //medium: 'https://medium.com/@',
    stackOverflow: 'https://stackoverflow.com/users/6714184/vinod-kumar',
    //gitlab: 'https://gitlab.com/',
    youtube: 'https://www.youtube.com/channel/UCYkEIYQioGV79Shso_KvUww'
    //ieee: 'https://ieeexplore.ieee.org/author/37088365152'
}