/* eslint-disable */
import girl1 from '../assets/svg/testimonials/girl1.svg'
import girl2 from '../assets/svg/testimonials/girl2.svg'
import girl3 from '../assets/svg/testimonials/girl3.svg'
import boy1 from '../assets/svg/testimonials/boy1.png'
import boy2 from '../assets/svg/testimonials/boy2.png'
import boy3 from '../assets/svg/testimonials/boy3.svg'



export const testimonialsData = [
    {
        id: 1,
        name: 'Deepak Pillai',
        title: 'Manager IT operations at Alshaya Group',
        text: "Vinod is a self-driven, extremely creative professional Who brings all of the skills and expertise in analysis. Working with Vinod is a rare opportunity to come across.",
        image: boy1
    },
    {
        id: 2,
        name: 'Dharapu Tharakeswara Reddy',
        title: 'Senior Business Analyst at Alshaya Group',
        text: "It was fantastic to work with Vinod, who was an exceptional team player. His hands on approach to effective speaking, consulting and research skills won him the respect of his teammates.",
        image: boy2
    },
    /*{
        id: 3,
        name: 'Phoebe Buffay',
        title: 'Massuese',
        text: "Heard of the song 'Smelly Cat!!' ? This website made it viral. Plus portfolio is the only thing that Ursula doesn't have :)",
        image: girl1
    }*/
]

